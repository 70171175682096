//
// Animations 
//

@keyframes ping {
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulse {
    50% {
        opacity: .5;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@keyframes slideLeft {
    0% {
        transform:translateX(0);
    }
    to {
        transform:translateX(-100%);
    }
}

@keyframes slideRight {
    0% {
        transform:translateX(-100%);
    }

    to {
        transform:translateX(0);
    }
}
