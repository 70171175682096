// Static classes

@each $color, $value in $colors {
    .bg-#{$color} {
        background-color: rgba($value, 1) !important;
    }
}

@each $color, $value in $theme-colors {

    // Light shades
    .bg-tint-#{$color} {
        background-color: tint-color($value, $bg-tint-level) !important;
    }

    // Dark shades
    .bg-shade-#{$color} {
        background-color: shade-color($value, $bg-shade-level) !important;
    }

    // Soft variants
    .bg-soft-#{$color} {
        background-color: shift-color($value, $bg-soft-level) !important;
    }
}

@each $color, $value in $surface-colors {
    .bg-surface-#{$color} {
        background-color: $value !important;
    }
}

// Shaped backgrounds

.bg-fade-light {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(243, 248, 255, 0)), to(#f3f8ff));
    background-image: -o-linear-gradient(top, rgba(243, 248, 255, 0), #f3f8ff);
    background-image: linear-gradient(to bottom, rgba(243, 248, 255, 0), #f3f8ff)
}

.bg-light-boxed-right {
    background: no-repeat left center -webkit-gradient(linear,left top,right top,from(#f3f8ff));
}

@media (min-width:1200px) {
    .bg-light-boxed-right {
        background-size: calc(1140px + (100vw - 1140px)/ 2) 100%
    }
}

.bg-checkered {
    background-repeat: no-repeat, repeat, repeat;
    background-image: -o-radial-gradient(transparent, transparent 50%, #fff), -o-linear-gradient(left, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), -o-linear-gradient(top, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
    background-image: radial-gradient(transparent, transparent 50%, #fff), linear-gradient(to right, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), linear-gradient(to bottom, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
    background-size: 100% 100%, calc(32px + 1px) calc(32px + 1px), calc(32px + 1px) calc(32px + 1px)
}

.bg-hero-gradient {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(180deg, #fff,hsla(0,0%,100%,.966) 13.11%,hsla(0,0%,100%,.874) 24.72%,hsla(0,0%,100%,.741) 35.26%,hsla(0,0%,100%,.583) 45.16%,hsla(0,0%,100%,.417) 54.84%,hsla(0,0%,100%,.259) 64.74%,hsla(0,0%,100%,.126) 75.28%,hsla(0,0%,100%,.034) 86.89%,hsla(0,0%,100%,0));
    }
}

// Translucent backgrounds

.bg-translucent-white {
    background-color: rgba($white, .05)!important;
}

.bg-translucent-dark {
    background-color: rgba($dark, .05)!important;
}

// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {

        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .bg#{$infix}-none {
            background-image: none !important;
        }

        .bg#{$infix}-between {
            background-repeat: no-repeat;
            background-position: left center, right center;
            background-size: auto 90%;
        }
    }
}
