//
// Helpers
//

@import "bootstrap/scss/helpers";


// Clearfix

.floatfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

//
// Scrollable areas
//

.scrollable-x {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
}

.scrollable-y {
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
}


// Fading elements

[class*="faded"],
[class*="blured"] {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 3.9375rem;
    }
}

.faded-top {
    &:after {
        top: 0;
        background: -moz-linear-gradient(bottom, transparent 0%, rgba(0,0,0,.6) 100%);
        background: -webkit-linear-gradient(bottom, transparent 0%, rgba(0,0,0,.6) 100%);
        background: linear-gradient(to top, transparent 0%, rgba(0,0,0,.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
    }
}

.faded-bottom {
    &:after {
        bottom: 0;
        background: -moz-linear-gradient(top, transparent 0%, rgba(0,0,0,.6) 100%);
        background: -webkit-linear-gradient(top, transparent 0%, rgba(0,0,0,.6) 100%);
        background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
    }
}

.blured-top {
    &:after {
        top: 0;
        background: rgba(255,255,255,.75);
        backdrop-filter: blur(10px) saturate(200%);
    }
}

.blured-bottom {
    &:after {
        bottom: 0;
        background: rgba(255,255,255,.75);
        backdrop-filter: blur(10px) saturate(200%);
    }
}


// Hover animations

[class*='hover-'] {
    @include transition($transition-base);
}

// Shadows

[class*="shadow"] {
	@include transition($transition-base);
}

// Contextual colors

a.text-muted,
a.text-heading,
a.text-current {
	&:hover {
		color: $link-hover-color;
	}
}

// a[class*=text-current]:hover {
//     color: $link-hover-color !important;
// }

// Fluid title with elipsis ending

.text-limit {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// SVG helpers
.svg-fluid {
    svg {
        max-width: 100%;
        height: 100%;
    }
}

.svg-current {
    svg [fill]:not([fill=none]) {
	    fill: currentColor !important;
   	}
}

// Image alignment
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Image filters

.img-grayscale {
    filter: grayscale(100%);
}

.img-saturate {
    filter: saturate(150%)
}

// SVG icons

.svg-icon {
    display: inline-flex;
    align-self: center;

    svg {
        width: $svg-icon-size;
        height: $svg-icon-size;
        position: relative;
    }

    &[class*=text-] {
		> svg [fill]:not([fill=none]) {
		   fill: currentColor !important;
	   	}
	}
}

.svg-align-baseline {
    svg {
        top: .125em;
    }
}


// Antialised

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialised {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}


// Line clamp

.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
