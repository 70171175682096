// Gradient
// options to pass to the utility API
//

@if(map-get($theme, gradient, disable) != true) {
    .gradient-top {
        background-image: linear-gradient(to top, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-top-right {
        background-image: linear-gradient(to top right, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-right {
        background-image: linear-gradient(to right, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-bottom-right {
        background-image: linear-gradient(to bottom right, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-bottom {
        background-image: linear-gradient(to bottom, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-bottom-left {
        background-image: linear-gradient(to bottom left, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-left {
        background-image: linear-gradient(to left, var(--#{$variable-prefix}gradient)) !important;
    }

    .gradient-top-left {
        background-image: linear-gradient(to top left, var(--#{$variable-prefix}gradient)) !important;
    }

    @each $color, $value in $colors {
        $end-color: rgba($value, 0);
        .start-#{$color},
        .start-#{$color}-hover:hover {
            --#{$variable-prefix}start-color: #{$value};
            --#{$variable-prefix}gradient: var(--#{$variable-prefix}start-color), var(--#{$variable-prefix}end-color, #{$end-color});
        }
    }

    @each $color, $value in $colors {
        $end-color: rgba($value, 0);
        .middle-#{$color},
        .middle-#{$color}-hover:hover {
            --#{$variable-prefix}gradient: var(--#{$variable-prefix}start-color), #{$value}, var(--#{$variable-prefix}end-color, #{$end-color});
        }
    }

    @each $color, $value in $colors {
        .end-#{$color},
        .end-#{$color}-hover:hover {
            --#{$variable-prefix}end-color: #{$value};
        }
    }
}
