// Height (dynamic height calc)
// options to pass to the utility API
//

// Dynamic height calculation
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .h#{$infix}-calc {
            --#{$variable-prefix}h#{$infix}: 0px;
            height: calc(100vh - var(--#{$variable-prefix}h#{$infix}));
        }

        .w#{$infix}-calc {
            --#{$variable-prefix}w#{$infix}: 0px;
            width: calc(100vw - var(--#{$variable-prefix}w#{$infix}));
        }

    }
}
